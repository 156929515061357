"use client";

import createVisitCookie from "@client/cookies/createVisitCookie";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

type Props = {
  enabled: boolean;
};

export function SetNeverbounceCookie({ enabled }: Props) {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  if (visitCookie && visitCookie?.neverbounceEnabled === undefined) {
    createVisitCookie({
      ...visitCookie,
      neverbounceEnabled: enabled,
    });
  }

  return <></>;
}
