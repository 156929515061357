"use client";

import Script from "next/script";

import {
  COOKIEBOT_ID,
  ENVIRONMENT,
  FACEBOOK_APP_ID,
  PRODUCTION,
} from "@shared/constants";

import { throwUndefinedVariable } from "@shared/functions/throwError";

type Props = {
  uuid: string;
};

export default function Facebook({ uuid }: Props) {
  if (!FACEBOOK_APP_ID) {
    throwUndefinedVariable("FACEBOOK_APP_ID");
  }

  return (
    <Script
      id="analytics-facebook"
      type={COOKIEBOT_ID ? "text/plain" : "text/javascript"}
      data-cookieconsent={COOKIEBOT_ID ? "marketing" : null}
    >
      {ENVIRONMENT === PRODUCTION ? (
        `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${FACEBOOK_APP_ID}', { external_id: '${uuid}' });`
      ) : (
        <></>
      )}
    </Script>
  );
}
