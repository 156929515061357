"use client";

import Script from "next/script";

export default function TrustPilot() {
  return (
    <Script
      id="analytics-trust-pilot"
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      async
    />
  );
}
