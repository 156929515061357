import {
  ENVIRONMENT,
  EVENT_JOURNEY_STARTED,
  EVENT_THANK_YOU_PAGE_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
  HOTJAR,
  PRODUCTION,
} from "@shared/constants";

import { logDebug, logError } from "@shared/functions/log";

import type DataLayer from "@client/classes/data-layer/data-layer";
import type Journey from "@client/classes/models/journey";
import type User from "@client/classes/models/user";
import type Visit from "@client/classes/models/visit";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";

type Window = typeof window & {
  hj: Function;
};

export default class HotjarEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  readonly dataLayer: typeof DataLayer;

  /**
   * Initialize `HotjarEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_JOURNEY_STARTED,
      this.handleJourneyStarted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_THANK_YOU_PAGE_VIEWED,
      this.handleThankYouPage.bind(this),
    );

    logDebug({ message: "Class 'HotjarEvents' initialized" });
  }

  /**
   * Send "Journey" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleJourneyStarted(event: string) {
    this.send(event, {
      cookie_id: this.dataLayer.user.getProp<User, "uuid">("uuid"),
      journey: this.dataLayer.journey.getProp<Journey, "name">("name"),
      journey_variation: this.dataLayer.journey.getProp<Journey, "variation">(
        "variation",
      ),
      journey_variation_updated: this.dataLayer.journey.getProp<
        Journey,
        "updated"
      >("updated"),
      journey_variation_published: this.dataLayer.journey.getProp<
        Journey,
        "published"
      >("published"),
      visit_id: this.dataLayer.visit.getProp<Visit, "visitId">("visitId"),
    });
  }

  /**
   * Send "Thank You Page" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleThankYouPage(
    event: string,
    { data }: Event.Data<Events.ThankYouPage>,
  ) {
    this.send(event, {
      thank_you_page_variation: data.variation,
    });
  }

  /**
   * Send "User Properties" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data User data
   */
  private send(event: string, data: Object) {
    const hotjarEvent = () => {
      if (ENVIRONMENT === PRODUCTION) {
        try {
          (window as Window).hj(
            "identify",
            this.dataLayer.user.getProp<User, "uuid">("uuid"),
            data,
          );
        } catch (error) {
          logError({
            message: "Hotjar function .hj() failed.",
            error,
            event: {
              name: event,
              meta: { data },
              destination: HOTJAR,
            },
          });
        }
      }

      this.dataLayer.events.log(event, data, HOTJAR);
    };

    if (this.dataLayer.events.nonEssentialScriptsReady) {
      hotjarEvent();
    } else {
      this.dataLayer.events.subscribe(
        EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
        () => {
          hotjarEvent();
        },
      );
    }
  }
}
