"use client";

import Script from "next/script";
import { useEffect } from "react";

import {
  COOKIEBOT_ID,
  EVENT_COOKIE_CONSENT_SUBMITTED,
  EVENT_COOKIE_CONSENT_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
} from "@shared/constants";

import DataLayer from "@client/classes/data-layer/data-layer";
import Event from "@client/components/analytics/components/event";
import createVisitCookie from "@client/cookies/createVisitCookie";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

type Props = {
  enabled: boolean;
};

export default function Cookiebot({ enabled }: Props) {
  useEffect(() => {
    const visitCookie = getVisitCookie() as Visit.Cookie;

    // If the visit cookie exists and the `cookiebotEnabled` key isn't set yet, set it
    if (visitCookie && visitCookie?.cookiebotEnabled === undefined) {
      createVisitCookie({
        ...visitCookie,
        cookiebotEnabled: enabled, // window.Cookiebot ? true : false,
      });
    }
  }, []);
  return enabled ? (
    <>
      <Script
        id="cookiebot-cmp-script"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={COOKIEBOT_ID}
        data-consentmode="disabled"
      />
      <Script
        id="cookiebot-event-listeners"
        onReady={() => {
          // Cookiebot banner is displayed to the user
          window.addEventListener(
            "CookiebotOnDialogDisplay",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_VIEWED);
            },
            false,
          );

          // User has consented to cookies
          window.addEventListener(
            "CookiebotOnAccept",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_SUBMITTED, {
                consented: true,
              });
            },
            false,
          );

          // User has declined cookies
          window.addEventListener(
            "CookiebotOnDecline",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_SUBMITTED, {
                consented: false,
              });
            },
            false,
          );

          window.addEventListener(
            "CookiebotOnTagsExecuted",
            function () {
              DataLayer.events.queue(EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS);
            },
            false,
          );
        }}
      >
        <></>
      </Script>
    </>
  ) : (
    <Event event={EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS} queue={true} />
  );
}
