import {
  ENVIRONMENT,
  EVENT_LEAD_FORM_SUBMITTED,
  EVENT_PAGE_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
  FACEBOOK,
  PRODUCTION,
} from "@shared/constants";

import { logDebug, logError } from "@shared/functions/log";

import type DataLayer from "@client/classes/data-layer/data-layer";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";

type Window = typeof window & {
  fbq: Function;
};

export const PAGE_VIEW_EVENT = "PageView";

export default class FacebookEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  readonly dataLayer: typeof DataLayer;

  /**
   * Initialize `FacebookEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_LEAD_FORM_SUBMITTED,
      this.handleLeadFormSubmitted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_PAGE_VIEWED,
      this.handlePageView.bind(this),
    );

    logDebug({ message: "Class 'FacebookEvents' initialized" });
  }

  /**
   * Send "page view" data
   */
  private handlePageView() {
    this.send(PAGE_VIEW_EVENT);
  }

  /**
   * Send "lead form submitted" data
   */
  private handleLeadFormSubmitted(
    _: string,
    { data }: Event.Data<Events.LeadFormSubmitted>,
  ) {
    data.fb_meta.forEach((s: { name: string; meta: any; event: any }) => {
      this.send(s.name, s.meta, s.event);
    });
  }

  /**
   * Send event data to facebook
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data to send
   * @param {any} custom Custom event data to send
   */
  private send(event: string, data: any = null, custom: any = null) {
    const facebookEvent = () => {
      if (ENVIRONMENT === PRODUCTION) {
        try {
          (window as Window).fbq("track", event, data, custom);
        } catch (error) {
          logError({
            message: "Facebook function fbq() failed.",
            error,
            event: {
              name: event,
              meta: { data, custom },
              destination: FACEBOOK,
            },
          });
        }
      }

      this.dataLayer.events.log(event, data, FACEBOOK);
    };

    if (this.dataLayer.events.nonEssentialScriptsReady) {
      facebookEvent();
    } else {
      this.dataLayer.events.subscribe(
        EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
        () => {
          facebookEvent();
        },
      );
    }
  }
}
