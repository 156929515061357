import {
  EVENT_LANDING_PAGE_VIEWED,
  EVENT_PAGE_VIEWED,
  FRONT_END_API,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";

import getVisitCookie from "@server/cookies/getVisitCookie";
import postPageView from "@server/front-end-api/postPageView";
import postVisit from "@server/front-end-api/postVisit";

import type DataLayer from "@client/classes/data-layer/data-layer";
import createVisitCookie from "@client/cookies/createVisitCookie";

import Visit from "@packages/types/visit";

export default class FrontEndApi {
  /**
   * Reference to the `DataLayer` class.
   */
  private dataLayer: typeof DataLayer;

  /**
   * Initialize `FrontEndApi` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_LANDING_PAGE_VIEWED,
      this.handleLandingPageView.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_PAGE_VIEWED,
      this.handlePageView.bind(this),
    );

    logDebug({ message: "Class 'FrontEndApi' initialized" });
  }

  /**
   * Send "landing page view" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleLandingPageView(event: string, data: Location) {
    postVisit(data.search);

    this.dataLayer.events.log(event, { search: data.search }, FRONT_END_API);
  }

  /**
   * Send "page view" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private async handlePageView(event: string, data: Location) {
    //TODO(Kyle): Update the types here when the api types are updated
    const response = (await postPageView(data.pathname, data.search)) as any;

    if (response.data) {
      const visitCookie = await getVisitCookie();

      if (visitCookie) {
        createVisitCookie({
          ...visitCookie,
          pageViewId: response.data?.page_view?.page_view_id,
        });
      }
    }

    this.dataLayer.events.log(
      event,
      {
        pathname: data.pathname,
        search: data.search,
      },
      FRONT_END_API,
    );
  }
}
