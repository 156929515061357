"use client";

import Script from "next/script";

import { EVENT_DATA_LAYER_INITIALIZED } from "@shared/constants";

import DataLayerInstance from "@client/classes/data-layer/data-layer";
import BrazeEvents from "@client/classes/data-layer/subscribers/braze-events";
// import CriteoEvents from "@client/classes/data-layer/subscribers/criteo-events";
import FacebookEvents from "@client/classes/data-layer/subscribers/facebook-events";
import FrontEndApi from "@client/classes/data-layer/subscribers/front-end-api";
import GTMEvents from "@client/classes/data-layer/subscribers/gtm-events";
// import HeapEvents from "@client/classes/data-layer/subscribers/heap-events";
import HotjarEvents from "@client/classes/data-layer/subscribers/hotjar-events";
import LandEvents from "@client/classes/data-layer/subscribers/land-events";

type Props = {
  uuid: string;
  visitId: string;
};

type Window = typeof window & {
  cpc: {
    data: typeof DataLayerInstance;
  };
};

export default function DataLayer({ uuid, visitId }: Props) {
  return (
    <Script
      id="analytics-data-layer"
      onReady={() => {
        /* Initialize Event Subscribers */
        new BrazeEvents(DataLayerInstance);
        new FacebookEvents(DataLayerInstance);
        new FrontEndApi(DataLayerInstance);
        new GTMEvents(DataLayerInstance);
        // new HeapEvents(DataLayerInstance);
        new HotjarEvents(DataLayerInstance);
        new LandEvents(DataLayerInstance);

        /* Fire "Data Layer Initialized" event */
        DataLayerInstance.events.trigger(EVENT_DATA_LAYER_INITIALIZED, {
          uuid,
          visitId,
        });

        (window as Window).cpc = { data: DataLayerInstance };
      }}
    >
      <></>
    </Script>
  );
}
