"use client";

import { useParams, usePathname } from "next/navigation";
import { useEffect } from "react";

export default function PageClasses() {
  const params = useParams<{ journey: string }>();
  const pathname = usePathname();

  useEffect(() => {
    const classes: string[] = [];
    const pathSegments = pathname.split("/");

    // Generate 'Journey' class (if applicable)
    if (pathSegments.length > 1) {
      if (params.journey) {
        classes.push(`page_journey_${params.journey}`);
      } else {
        classes.push(`page_journey_${pathSegments[1]}`);
      }
    }

    // Generate 'Name' class
    if (pathname === "/") {
      classes.push("page_name_home");
    } else {
      let pageName = pathname.slice(1).replaceAll("/", "-");

      if (params.journey) {
        pageName = pageName.replace(new RegExp(`${params.journey}-?`), "");

        if (pageName === "") {
          pageName = "landing-page";
        }
      }

      classes.push(`page_name_${pageName}`);
    }

    // Generate 'Flow' class
    if (pathname.includes("offers")) {
      classes.push("page_flow_secondary-offers");
    }

    // Add 'page' classes
    document.getElementById("page")?.classList.add(...classes);

    // Remove 'page' classes
    return () => {
      document.getElementById("page")?.classList.remove(...classes);
    };
  }, [params, pathname]);

  return null;
}
