"use client";

import Cookies from "js-cookie";

import { USER_COOKIE_MAX_AGE, USER_COOKIE_NAME } from "@shared/constants";

import { throwUndefinedVariable } from "@shared/functions/throwError";

import type User from "@packages/types/user";

export default function createUserCookie(props: User.Cookie) {
  if (!USER_COOKIE_MAX_AGE) {
    throwUndefinedVariable("USER_COOKIE_MAX_AGE");
  }

  if (!USER_COOKIE_NAME) {
    throwUndefinedVariable("USER_COOKIE_NAME");
  }

  Cookies.set(USER_COOKIE_NAME, JSON.stringify(props), {
    expires: USER_COOKIE_MAX_AGE / 86400, // USER_COOKIE_MAX_AGE is defined in seconds, `expires` requires days (1d === 86400s)
    sameSite: "strict",
  });
}
