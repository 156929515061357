"use client";

import { useEffect } from "react";

import createUserCookie from "@client/cookies/createUserCookie";

import type User from "@packages/types/user";

/**
 * Create User cookie in the Browser
 *
 * @param props A User Cookie object
 */
export default function UserCookie(props: User.Cookie) {
  useEffect(() => {
    createUserCookie(props);
  }, [props]);

  return null;
}
