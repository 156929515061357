export function throwError(message: string) {
  throw new Error(message);
}

export function throwUndefinedVariable(variable: string) {
  throw new Error(`Config: ${variable} is undefined.`);
}

export function throwIfVariableNullOrEmpty(variable: string) {
  throw new Error(
    `Config: ${variable} is either undefined OR an empty string.`,
  );
}
